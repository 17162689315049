@charset "utf-8";
&-h3 {
	margin: 0 0 40px;
	font-size: 3.0rem;
	line-height: 1.4;
	color: $baseColor;
	@include font-serif-bold;
	position: relative;
	padding: 0 0 30px 0;
	&::after {
		content: "";
		height: 4px;
		width: 70px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(to right, #2b51a7 0%,#0f1d5b 100%);
	}
	&._al-c_ {
		text-align: center;
		&::after {
			@include centering-elements(true, false);
			bottom: 0;
		}
	}
	&._zaiko_ {
		font-size: $fz-xxxl;
		padding: 0 0 20px;
		&::after {
			width: 45px;
			height: 2px;
			background: #9CA8DF;
		}
	}
}

@include media-s {
	&-h3 {
		margin: 0 0 20px;
		font-size: $fz-xl;
		padding: 0 0 20px 0;
	}
}
