@charset "utf-8";
&-grid {
	display: flex;
	flex-wrap: wrap;
	
	&:not([class*="_col"]) {
		margin-left: -$margin / 2;
		margin-right: -$margin / 2;
	}
	
	&._characteristic_ {
		justify-content: space-between;
	}
	
	.__col {
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		
		@for $i from 1 through $maxcol {
			&._col-#{$i}_ {
				@include column($i);
			}
		}
		img {
			max-width: 100%;
		}
		&._c-txt_ {
			width: calc(100% - 380px);
		}
		&._c-img_ {
			max-width: 330px;
		}
	}
	&._stretch_ {
    .__col {
      display: flex;
    }
  }	
}
@include media-m-ov {
	&-grid {
		.__col {
			@for $i from 1 through $maxcol {
				&._col-lg-#{$i}_ {
					@include column($i);
				}
			}
		}
		
		//2カラム -----------------------------------
		&._col2_ {
			.__col {
				flex-basis: calc((100% - #{$margin}) / 2);
				@include iehack {
					flex-basis: calc((100% - #{$margin} - 1px) / 2);
				}
				&:not(:nth-child(2n+1)) {
					margin-left: $margin;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: $margin;
				}
			}
			&._wide_ {
				.__col {
					flex-basis: calc((100% - (#{$margin} * 1.5)) / 2);
					@include iehack {
						flex-basis: calc(((100% - (#{$margin} * 1.5)) - 1px) / 2);
					}
					&:not(:nth-child(2n+1)) {
						margin-left: calc(#{$margin} * 1.5);
					}
					&:not(:nth-child(-n+2)) {
						margin-top: calc(#{$margin} * 1.5);
					}
				}
			}
		}

		//3カラム -----------------------------------
		&._col3_ {
			.__col {
				flex-basis: calc((100% - #{$margin} * 2) / 3);
				@include iehack {
					flex-basis: calc((100% - #{$margin} * 2 - 1px) / 3);
				}
				&:not(:nth-child(3n+1)) {
					margin-left: $margin;
				}
				&:not(:nth-child(-n+3)) {
					margin-top: $margin;
				}
			}
			&._wide_ {
				.__col {
					flex-basis: calc((100% - (#{$margin} * 1.5) * 2) / 3);
					@include iehack {
						flex-basis: calc(((100% - (#{$margin} * 1.5) * 2) - 1px) / 3);
					}
					&:not(:nth-child(3n+1)) {
						margin-left: calc(#{$margin} * 1.5);
					}
					&:not(:nth-child(-n+3)) {
						margin-top: calc(#{$margin} * 1.5);
					}
				}
			}
		}

		//4カラム -----------------------------------
		&._col4_ {
			.__col {
				flex-basis: calc((100% - #{$margin} * 3) / 4);
				@include iehack {
					flex-basis: calc((100% - #{$margin} * 3 - 1px) / 4);
				}
				&:not(:nth-child(4n+1)) {
					margin-left: $margin;
				}
				&:not(:nth-child(-n+4)) {
					margin-top: $margin;
				}
				//news-list -----------------------------------
				&.c-news-list__item {
					margin: 0 10px 20px;
					flex-basis: calc((100% - #{$margin} * 4) / 4);
					background: $white;
					@include iehack {
						flex-basis: calc((100% - #{$margin} * 4 - 1px) / 4);
					}
					&:not(:nth-child(4n+1)) {
						margin-left: 10px;
					}
					&:not(:nth-child(-n+4)) {
						margin-top: 0;
					}
				}
			}
			&.c-index-newsBody {
				margin: 0 -10px;
			}
		}

		//5カラム -----------------------------------
		&._col5_ {
			.__col {
				flex-basis: calc((100% - #{$margin} * 4) / 5);
				@include iehack {
					flex-basis: calc((100% - #{$margin} * 4 - 1px) / 5);
				}
				&:not(:nth-child(5n+1)) {
					margin-left: $margin;
				}
				&:not(:nth-child(-n+5)) {
					margin-top: $margin;
				}
			}
		}
	}
}
@include media-m {
	&-grid {
		.__col {
			@for $i from 1 through $maxcol {
				&._col-md-#{$i}_ {
					@include column($i);
				}
			}
		}
	}
}
@include media-s {
	&-grid {
		.__col {
			@for $i from 1 through $maxcol {
				&._col-#{$i}_ {
					@include sp-column($i);
				}
				&._col-sm-#{$i}_ {
					@include sp-column($i);
				}
				&._c-img_ {
					max-width: 100%;
				}
			}
			&._ta-c_ {
				text-align: center;
			}
		}
		
		//2～5カラム（SP表示は2カラムを基本とする） -----------------------------------
		&._col2_,
		&._col3_,
		&._col4_,
		&._col5_ {
			.__col {
				flex-basis: calc((100% - #{$margin-sp}) / 2);
				@include iehack {
					flex-basis: calc((100% - #{$margin-sp} - 1px) / 2);
				}
				&:not(:nth-child(2n+1)) {
					margin-left: $margin-sp;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: $margin-sp;
				}
				//news-list -----------------------------------
				&.c-news-list__item {
					margin: 0 5px 10px;
					flex-basis: calc((100% - #{$margin-sp} * 2) / 2);
					@include iehack {
						flex-basis: calc((100% - #{$margin-sp} * 2 - 1px) / 2);
					}
					&:not(:nth-child(2n+1)) {
						margin-left: 5px;
					}
					&:not(:nth-child(-n+2)) {
						margin-top: 0;
					}
				}
			}
			&._wide_ {
				.__col {
					&:not(:nth-child(-n+2)) {
						margin-top: $margin-sp * 2;
					}
				}
			}
		}
		
		
		//スマホ強制1カラム -----------------------------------
		&._sp-col1_ {
			.__col {
				flex: 0 0 100%;
				width: 100%;

				@include iehack {
					flex: 0 0 100%;
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 30px;
				}
			}
		}
		&._sp-col-r_ {
			flex-direction: column-reverse;
			.__col {
				@include iehack {
					flex: auto;
					min-height: 0%;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 0;
					margin-bottom: 30px;
				}
			}
		}
	}
}
