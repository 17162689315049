@charset "utf-8";
&-visual {
	position: relative;
	width: 100%;
	&__slider {
		width: 100%;
		&.slick-dotted.slick-slider {
			margin-bottom: 0;
		}
	}
	&__item {
		position: relative;
		height: 0!important;
		padding-bottom: 57.14%;
		overflow: hidden;
		min-height: inherit!important;
		.__elem {
			top: 0;
			left: 0;
			width: 100%;
		}
		&._video_ {
			.__elem {
				width: auto;
				@include centering-elements(true, true);
				min-height: 100%;
				min-width: 100%;
			}
		}
	}
	&__text {
		@include centering-elements(true, true);
		@include font-a1;
		color: $white;
		font-size: 5.5rem;
		font-weight: $font-bold;
		line-height: 1.5;
		width: 100%;
		text-align: center;
	}
	.slick-dots {
		max-width: 1160px;
		text-align: right;
		font-size: 0;
		line-height: 0;
		@include centering-elements(true, false);
		bottom: 50px;

		li {
			width: 50px;
			height: 5px;
			margin: 0 3px;
			button {
				width: 50px;
				height: 5px;
				padding: 0;
				background: $white;
				&::before {
					content: none;
					
				}
			}
			&.slick-active button {
				background: $baseColor;
			}
		}
	}
	.slick-arrow {
		display: none;
	}
}


@include media-m-ov {
	&-visual {
		
	}
}

@include media-m {
	&-visual {

	}
}
@include media-s {
	&-visual {
		&__text {
			font-size: 3.8rem;
		}
		&__item {
			position: relative;
			height: 0!important;
			padding-bottom: 160%;
			overflow: hidden;
			min-height: inherit!important;
			.__elem {
				top: 0;
				left: 0;
			}
			&._video_ {
				.__elem {
					@include centering-elements(true, true);
				}
			}
		}
		.slick-dots {
			max-width: 100%;
			bottom: 30px;
			left: inherit;
			transform: translate( 0, 0);
			right: 20px;
			li {
				width: 30px;
				button {
					width: 30px;
				}
			}
		}
	}
}
