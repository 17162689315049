@charset "utf-8";
&-box {
	padding: 25px;
	background: $white;

	&._gray_ {
		background: #F1F1F1;
	}
	&._line-blue_ {
		border: 1px solid #9CA8DF;
	}
	
	&._pink_ {
		background: #FFF1F1;
		border: 1px solid #FFCFCF;
	}
	&._lightblue_ {
		background: $bg-blueLight02;
	}
	&._zaiko_ {
		color: $text-black;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		@include transition;
		padding: 25px 25px 60px;
		position: relative;
		&:hover {
			background: $baseColor;
			color: $white;
			.e-h3._zaiko_ {
				color: $white;
			}
		}
		.__ico {
			@include centering-elements(true, false);
			bottom: 15px;
		}
	}
}
@include media-s {
	&-box {
		padding: 15px;
	}
}