@charset "utf-8";
&-entry {
	&__head {
		margin: 0 0 40px;
		display: flex;
		flex-wrap: wrap;
		.__time {
			margin: 0 15px 15px 0;
		}
		.e-label {
			margin: 0 15px 15px 0;
		}
		.__club {
			margin: 0 0 15px;
			padding: 0 0 0 10px;
			position: relative;
			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 6px;
				height: 1px;
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
				border-bottom: 1px solid #7A7A7A;
				content: "";
			}
		}
		.__ttl {
			width: 100%;
		}
	}
	&__img-column2 {
		display: flex;
		justify-content: space-between;
		
		& > * {
			margin: 0 10px;
		}
	}
	&__block {
		strong {
			font-weight: $font-medium;
		}
		em {
			font-weight: $font-medium;
			color: $red;
		}
		p {
			margin: 0 0 15px;
			&:last-child {
				margin: 0;
			}
		}
	}
}

@include media-m-ov {
	&-entry {
		&__block {
			a img {
				@include transition;
				&:hover{
					opacity: 0.7;
				}
			}
		}
	}
}

@include media-s {
	&-entry {
		&__head {
			margin: 0 0 30px;
			.__time {
				margin: 0 10px 10px 0;
			}
			.e-label {
				margin: 0 5px 10px 0;
				&._club-name_ {
					margin: 0 0 10px 0;
				}
			}
			.__club {
				margin: 0 0 10px;
			}
		}
		&__img-column2 {
			&._sp-col1_ {
				display: block;

				& > * {
					margin: 0 0 15px;
				}
			}
		}
		&__block {
			img {
				max-width: 100%;
			}
		}
	}
}
