@charset "utf-8";
&-contact-form {
	padding-top: 80px;
	
	&__inner {
		margin-top: -80px;
		padding-top: 80px;
	}
}
@include media-s {
	&-contact-form {
		padding-top: 50px;

		&__inner {
			margin-top: -50px;
			padding-top: 50px;
		}
	}
}
