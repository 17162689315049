@charset "utf-8";
&-bnr {
	&__body {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 80px) / 4 );
	}
	&__link {
		position: relative;
		padding-top: 56.14%;
		overflow: hidden;
		display: block;
		&._opacity_ {
			&:hover {
				opacity: 0.7;
			}
		}
		.__elem {
			width: 100%;
			@include centering-elements(true, false);
			top: 0;
		}
	}
}

@include media-m-ov {
	&-bnr {

	}
}
@include media-s {
	&-bnr {
		&.l-bg {
			padding: 30px 0;
		}
		&__item {
			width: calc(50% - 10px);
			margin: 5px 5px;
		}
	}
}
