@charset "utf-8";
&-h6 {
	margin: 0 0 20px;
	padding: 15px 20px;
	font-size: $fz-m;
	line-height: 1.4;
	@include font-medium;
	background: $bg-blueLight02;
} 

@include media-s {
	&-h6 {
		margin: 0 0 15px;
		padding: 10px 15px;
		font-size: $fz-m;
	}
}
