@charset "utf-8";
&-catch01 {
	margin: 0 0 15px;
	color: $baseColor02;
	font-size: 2.4rem;
	line-height: 1.5;
	@include font-serif-bold;
	&._fz-xl_ {
		font-size: $fz-xl;
	}
	&._fz-l_ {
		font-size: $fz-l;
	}
	&._c-black_ {
		color: $black;
	}
}

@include media-s {
	&-catch01 {
		margin: 0 0 15px;
		font-size: $fz-xxl;
	}
}
