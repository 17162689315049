@charset "utf-8";
&-breadcrumb {
	margin: 0 0 40px;
	padding: 10px 0 0;
	font-size: $fz-s;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1240px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		&:not(:last-child) {
			text-decoration: none;
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
	&__link {
		
	}
}
@include media-s {
	&-breadcrumb {
		margin: 0 0 25px;
		font-size: $fz-xxs;
	
		&__inner {
			padding: 0 15px;
		}
	}
}
