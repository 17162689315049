@charset "utf-8";
&-a-txt-td {
	text-decoration: underline;
	&._td-nb_ {
		text-decoration: none;
		color: inherit;
	}

}
@include media-m-ov {
	&-a-txt-td {
		&._td-nb_ {
			&:hover {
				color: $baseColor;
			}
		}
	}
}
