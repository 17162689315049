@charset "utf-8";
&-contents {
	margin: 0 0 30px;		
}

@include media-m-ov {
	&-contents {
		
	}
}

@include media-s {
	&-contents {
	margin: 0 0 30px;		
	}
}


