@charset "utf-8";
&-pagetop {
	position: fixed;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction:column; 
		width: 50px;
		height: 50px;
		background: rgba(#E5EDF9, 0.8);
		color: $baseColor;
		font-size: $fz-xxxs;
		text-decoration: none;
		line-height: 1.0;
		@include transition;
		
		&:hover {
			@include opacity;
		}
		.__txt {
			margin-top: 5px;
			@include font-serif;
			font-size: 0.9rem;
		}
	}
}
@include media-m-ov {
	&-pagetop {
		right: 280px;
		bottom: 0;
	}
}

@include media-m {
	&-pagetop {
		right: 0;
	}
}

@include media-s {
	&-pagetop {
		z-index: 3;
		right: 0;
		bottom: 50px;
	}
}
