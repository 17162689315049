@charset "utf-8";
&-modal {
	&.iziModal {
		font-size: $fz-m;
		line-height: 1.6;
	}
}
@include media-s {
	&-modal {
		&.iziModal {
			margin-left: 15px;
			margin-right: 15px;
		}
	}
}