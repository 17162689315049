@charset "utf-8";
&-catch02 {
	margin: 0 0 15px;
	color: $baseColor02;
	font-size: 2.4rem;
	line-height: 1.3;
	font-weight: $font-medium;
	&._fz-xl_ {
		font-size: $fz-xl;
	}
	&._fz-l_ {
		font-size: $fz-l;
	}
	&._c-black_ {
		color: $black;
	}
}

@include media-s {
	&-catch02 {
		margin: 0 0 15px;
		font-size: $fz-xxl;
	}
}
