@charset "utf-8";

$num: 5;

// ============================================================
// color
// ============================================================
$baseColor: #0F1D5B;
$baseColor02: #0D3387;
$baseColorLight: #8C99CF;
$baseColorDarken: #121A3E;

$text-black: #333;
$text-link: #5060AB;

$line-gray: #D1D1D1;
$line-blue: #8190B2;
$line-blue02: #C6CBE6;

$bg-blueLight: #E0E3F1;
$bg-blueLight02: #F3F4F9;
$bg-gray: #E2E5EB;

$red: #EB2121;
$deep-red: #A71111;
$white: #ffffff;
$black: #000000;
$black2: #2A2A2A;
$green: #235318;
$blue: #284B9E;
$gray1: #444444;
$brown: #947642;


// ============================================================
// font size
// ============================================================
$fz-xxxs: 1.0rem;
$fz-xxs: 1.2rem;
$fz-xs: 1.3rem;
$fz-s: 1.4rem;
$fz-m: 1.6rem;
$fz-l: 1.8rem;
$fz-xl: 2.0rem;
$fz-xxl: 2.2rem;
$fz-xxxl: 2.4rem;

// ============================================================
// font-weight
// ============================================================
$font-thin: 100;
$font-light: 200;
$font-demilight: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 900;

// ============================================================
// マージンサイズ
// ============================================================
$m-xxxs: 10px;
$m-xxs: 15px;
$m-xs: 20px;
$m-s: 25px;
$m-m: 30px;
$m-l: 60px;
$m-xl: 80px;
$m-xxl: 100px;
$m-xxxl: 150px;


// ============================================================
// grid
// ============================================================
$maxcol: 12;
$max-width: 100%;
$margin: 20px; //PC用 カラム間余白
$margin-sp: 10px; //SP用 カラム間余白
