@charset "utf-8";
&-note {
	font-size: $fz-s;
	margin: 5px 0 0 0;
	line-height: 1.5;
}

@include media-s {
	&-note {
		font-size: $fz-xs;
	}
}
