@charset "utf-8";
&-block {
	margin-bottom: 40px;
	&._mb-s_ {
		margin-bottom: 15px;
	}
	&._mb-m_ {
		margin-bottom: 30px;
	}
	&._mb-l_ {
		margin-bottom: 60px;
	}
}
@include media-s {
	&-block {
		margin-bottom: 20px;
		&._mb-s_ {
			margin-bottom: 10px;
		}
		&._mb-m_ {
			margin-bottom: 20px;
		}
		&._mb-l_ {
			margin-bottom: 40px;
		}
	}
}