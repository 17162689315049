@charset "utf-8";
@include media-m-ov {
	&-fix-menu {
		position: fixed;
		top: 50%;
		right: 0;
		display: flex;
		flex-direction: column;
		transform: translateY(-50%);
		z-index: 5;

		&__btn {
			display: flex;
			flex-direction: column;
			width: 50px;
			padding: 20px 0;
			background: $baseColor;
			color: $white;
			font-size: 1.5rem;
			text-align: center;
			text-decoration: none;
			line-height: 50px;		
			@include transition;
			@include font-serif;
			border-top: 1px solid #2C3974;
			&:first-child {
				border-top: 0;
				border-top-left-radius: 5px;
			}
			&:last-child {
				border-bottom-left-radius: 5px;
			}
			&:hover {
				background: #121A3E;
				color: $white;
			}
			&._icon_ {
				background: $black2;
				border-top: 1px solid #373737;
				&:hover {
					background: $black;
				}
			}
		}
		&__text {
			writing-mode: vertical-rl;
			transform: rotate(0.001deg);
		}	
	}
}
@include media-s {
	&-fix-menu {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 5;
		display: flex;
		height: 50px;
		justify-content: center;
		width: 100%;
		&__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			background: $baseColor;
			color: $white;
			font-size: 1.5rem;
			text-align: center;
			text-decoration: none;	
			@include font-serif;
			border-left: 1px solid #2C3974;
			width:  30%;
			&:first-child {
				border-left: 0;
				width:  40%;
			}
			&._icon_ {
				width: 15%;
				background: $black2;
				border-left: 1px solid #373737;
				border-right: 1px solid #373737;
				&:hover {
					background: $black;
				}
				&:last-child {
					border-right: 0;
					border-left: 0;
				}
			}
		}
	}
}
