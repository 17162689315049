@charset "utf-8";
&-footer {
	background: #242424;
	line-height: 1.6;
	padding: 50px 0 0 0;
	&__inner {
		max-width: 1200px;
		margin: 0 auto;
		border-left: 1px solid #2D2D2D;
		border-right: 1px solid #2D2D2D;
	}
	&Logo {
		display: flex;
		justify-content: center;
	}
	&Fnav {
		border-top: 1px solid #2D2D2D;
		width: 100%;
		margin: 50px 0 0;
		color: $white;
		line-height: 1.5;
		display: flex;
		&__item {
			margin: 0 0 30px;
		}
		&__link {
			color: $white;
			font-size: 1.5rem;
			position: relative;
			width: 100%;
			padding: 0 20px 0 40px;
			text-decoration: none;
			display: table;
			&._link_{
				.icon-filter_none {
					color: $white;
				}
			}
		}
		&__sub-link {
			color: #D1D1D1;
			font-size: $fz-xs;
			text-decoration: none;
		}
		&__btn {
			border: 1px solid #7A7A7A;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto 15px;
			width: 75%;
			color: $white;
			text-decoration: none;
			height: 60px;
			@include transition;
			font-size: $fz-s;
			.__ico {
				font-size: $fz-l;
				margin: 0 5px 0 0;
				color: #ABB2C4;
			}
		}
	}

	&Copyright {
		border-top: 1px solid #2D2D2D;
		padding: 20px 30px;
		background: #191919;
		color: #727272;
		font-size: $fz-xxs;
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		&__add {
			.__tel {
				color: #727272;
			}
		}
		&__copy {
			@include font-serif;
		}
	}
}
@include media-m-ov {
	&-footer {
		&Fnav{
			&__wrap {
				width: 25%;
				border-right: 1px solid #2D2D2D;
				padding: 50px 0;
				&:last-child {
					border-right: none;
				}
			}
			&__link {
				.__ico {
					display: none;
				}
			}
			&__link {
				&::before {
					@include centering-elements(false, true);
					left: 0;
					content: "";
					width: 20px;
					height: 1px;
					border-bottom: 1px solid #2D2D2D;
				}
				&._link_{
					@include transition;
					&:hover {
						opacity: 0.7;
						text-decoration: underline;
						color: $white;
					}
				}
			}
			&__dropdown {
				margin: 10px 0 0 0;
			}
			&__sub-item {
				margin: 0 0 10px 50px;
			}
			&__sub-link {
				@include transition;
				&:hover {
					opacity: 0.7;
					text-decoration: underline;
					color: $white;
				}
			}
			&__btn {
				&:hover {
					background: #A0A8CF;
					color: $baseColor;
					.__ico {
						color: $baseColor;
					}
				}
			}
		}
		&Copyright {
			&__link {
				display: none;
			}
		}
	}
}

@include media-m {
	&-footer {
		&Fnav {
			&__link {
				padding: 0 20px 0 25px;
				font-size: $fz-s;
				&::before {
					width: 15px;
				}
			}
		}
	}
}

@include media-s {
	&-footer {
			padding: 0 15px 50px;
		&__inner {
			padding: 25px 0 0 0;
			border-left: 1px solid #3A3A3A;
			border-right: 1px solid #3A3A3A;
		}
		&Logo {
			.__elem {
				max-width: 200px;
			}
		}
		&Fnav {
			border-top: 1px solid #3A3A3A;
			margin: 25px 0 0;
			display: block;
			&__item {
				border-bottom: 1px solid #3A3A3A;
				margin: 0;
				width: 100%;	
			}
			&__link {
				display: block;
				padding: 15px;
				text-decoration: none;
				position: relative;
				.__ico {
					@include centering-elements(false, true);
					right: 15px;
					@include transition;
				}
				&._active_ {
					.__ico:before {
						content: "\f106";
					}
				}
			}
			&__dropdown {
				display: none;
				background: #191919;
				padding: 10px 0;
			}
			&__sub-link {
				padding: 10px 15px;
				display: block;
				width: 100%;
				height: 100%;
			}
			&__type {
				padding: 30px 0;
			}
			&__btn {
				width: 80%;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&Copyright {
			display: block;
			text-align: center;
			padding:  20px 0;
			&__add {
				margin: 0 0 20px;
			}
			&__link {
				display: flex;
				margin: 0 auto 20px;
				width: 80%;
				justify-content: center;
			}
			&__btn {
				border: 1px solid #3A3A3A;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				background: #242424;
				padding: 10px 0;
				width: 50%;
				text-decoration: none;
				font-size: $fz-s;
				.__ico {
					font-size: $fz-l;
					margin: 0 5px 0 0;
					color: #ABB2C4;
				}
				&:first-child {
					border-right: 0;
				}
			}
			&__copy {
				font-size: $fz-xxxs;
				margin: 0 15px;
			}
		}
	}
}
