@charset "utf-8";
&-label {
	width: 90px;
	height: 28px;
	color: $white;
	font-size: $fz-xs;
	text-align: center;
	line-height: 28px;
	background: $baseColor;
	&._event_ {
		background: $green;
	}
	&._life_ {
		background: #284B9E;
	}
	&._other_ {
		background: $gray1
	}
	&._student_ {
		background: $brown;
	}
	&._club_ {
		background: $deep-red;
	}
	&._club-name_ {
		background: $baseColor;
		min-width: 90px;
		width: auto;
		padding: 0 5px;
		
	}
	&._h-s_ {
		height: 22px;
		line-height: 22px;
	}
	&._zaiok_ {
		width: auto;
		padding: 0 20px;
		margin: 0 10px 0 0;
	}
}

@include media-s {
	&-label {
		
	}
}
