@charset "utf-8";
&-h4 {
	position: relative;
	margin: 0 0 20px;
	padding: 0 0 15px;
	border-bottom: 1px solid $line-blue02;
	font-size: $fz-xxl;
	line-height: 1.4;
	@include font-serif-bold;
	color: $baseColor02;
}

@include media-s {
	&-h4 {
		margin: 0 0 15px;
		padding: 0 0 10px;
		font-size: $fz-l;

		&::after {
			width: 58px;
		}
	}
}
