@charset "utf-8";
&-topic {
	
}

@include media-m-ov {
	&-topic {
		.c-grid {
			align-items: center;
		}
		&__btn {
			.e-btn {
				padding: 0 20px;
			}
		}
	}
}
@include media-s {
	&-topic {
		&__head.__col._col-sm-12_ {
			margin-bottom: 0;
		}
		&__btn {
			margin: 0 auto;
			width: 70%;
			.e-btn {
				width: 100%;
			}
		}
	}
}
