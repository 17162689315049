@charset "utf-8";
&-list-number {
	counter-reset: number 0;
	&__item {
		position: relative;
		padding-left: 1.2em;
		
		&:not(:first-child) {
			margin-top: 5px;
		}
		&::before {
			counter-increment: number;
			content: counter(number);
			color: $baseColor;
			display: block;
			position: absolute;
			left: 0;
		}
	}
	&._kanji_ &__item {
		padding-left: 2.0em;
		&::before {
			counter-increment: number 1;
			content: counter(number, cjk-ideographic);
		}
	}
	&._red_ &__item {
		&::before {
			color: $red;
		}
	}
	&._black_ &__item {
		&::before {
			color: $text-black;
		}
	}
	&._space_ {
		padding: 0 0 0 1em;
	}
}
@include media-s {
	&-list-number {
	}
}
