@charset "utf-8";
&-btn-arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	min-width: 150px;
	height: 45px;
	margin: 10px 5px;
	padding: 0 30px;
	background: $white;
	color: $baseColor;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	border: 1px solid $baseColor;
	@include transition;
	.__ico {
		@include centering-elements(false, true);
		
		&:first-child { left: 15px; }
		&:last-child { right: 15px; }
	}
	&:not(._disabled_) {
		cursor: pointer;
	}
	&._disabled_ {
		opacity: .5;
	}
	
	//サイズオプション Lサイズ-------------------------------
	&._w-l_ {
		min-width: 300px;
		height: 60px;
		font-size: $fz-m;
	}
	//サイズオプション Mサイズ-------------------------------
	&._w-m_ {
		min-width: 240px;
		font-size: $fz-m;
	}
	//サイズオプション Sサイズ-------------------------------
	&._w-s_ {
		min-width: inherit;
		height: 40px;
	}
}

@include media-m-ov {
	&-btn-arrow {
		&:not(._disabled_):hover {
			background: $baseColor;
			color: $white;
			.__ico:before {
				color: $white;
			}
		}
	}
}

@include media-s {
	&-btn-arrow {
		height: 50px;
		margin: 5px;
		font-size: $fz-m;
		
		.__ico {
			&:first-child { left: 10px; }
			&:last-child { right: 10px; }
		}
		
		//サイズオプション Lサイズ-------------------------------
		&._w-l_ {
			width: 100%;
			min-width: 0;
			height: 50px;
			margin: 10px 0;
		}
		//サイズオプション Mサイズ-------------------------------
		&._w-m_ {
			min-width: 70%;
			font-size: $fz-m;
		}
		//サイズオプション Sサイズ-------------------------------
		&._w-s_ {
			min-width: 0;
			min-height: 35px;
			padding: 0 20px;
			font-size: $fz-xxs;
		}
	}
}