@charset "utf-8";
&-accordion {
	cursor: pointer;
    background: $bg-blueLight02;
    padding: 15px 50px 15px 25px;
    position: relative;
	font-weight: $font-medium;
	@include transition;
	&::after {
		@include transition;
		@include centering-elements(false, true);
		right: 15px;
		content: "\e90a";
		font-family: 'icomoon';
		font-size: 2.8rem;
		color: #8C99CF;
	}
	&._active_ {
		&::after {
			content: "\e909";
		}
	}
	&:hover {
		color: $text-link;
		&::after {
			color: $baseColor;
		}
	}
	&._faq_ {
		min-height: 50px;
		padding: 10px 50px 10px 70px;
		position: relative;
		display: flex;
		align-items: center;
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			width: 50px;
			height: 100%;
			content: "Q";
			background: #8C99CF;
			color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: $fz-l;
			font-weight: $font-medium;
		}
	}
	&__hide {
		display: none;
		padding: 15px 0 0;
		&._faq_ {
			padding-left: 70px;
			position: relative;
			&::before {
				position: absolute;
				left: 0;
				top: 15px;
				width: 50px;
				height: 50px;
				content: "A";
				background: $bg-blueLight;
				color: $baseColor;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $fz-l;
				font-weight: $font-medium;
			}
		}
	}
}
@include media-s {
	&-accordion {
		padding: 10px 35px 10px 20px;
		font-size: $fz-m;
	}
}