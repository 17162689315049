@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?acyzog');
  src:  url('/common/icon/fonts/icomoon.eot?acyzog#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?acyzog') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?acyzog') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?acyzog') format('woff'),
    url('/common/icon/fonts/icomoon.svg?acyzog#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pagetop:before {
  content: "\e90b";
}
.icon-file-pdf-o:before {
	content: "\f1c1";
	color: $red;
}
.icon-file-word-o:before {
	content: "\f1c2";
	color: #225998;
}
.icon-file-excel-o:before {
	content: "\f1c3";
	color: #077D4A;
}
.icon-event:before {
  content: "\e906";
}
.icon-exam:before {
  content: "\e907";
}
.icon-slick-next:before {
  content: "\e904";
}
.icon-slick-prev:before {
  content: "\e905";
}
.icon-map:before {
  content: "\e900";
}
.icon-graduate:before {
  content: "\e901";
}
.icon-info:before {
  content: "\e902";
}
.icon-student:before {
  content: "\e903";
}
.icon-remove:before {
  content: "\e909";
}
.icon-add:before {
  content: "\e90a";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-filter_none:before {
  content: "\e908";
}
