@charset "utf-8";
&-foot-catch {
	background: url(/common/img/common/bg_foot_pc.jpg) center center no-repeat;
	background-size: cover;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-size: 3.3rem;
	@include font-a1;
}
@include media-s {
	&-foot-catch {
		height: 200px;
		background: url(/common/img/common/bg_foot_sp.jpg) center center no-repeat;
		background-size: cover;
		font-size: $fz-xxl;
		min-height: 180px;
	}
}
