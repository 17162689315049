@charset "utf-8";
&-pager {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0 5px;
	grid-template-areas: "prev list next";
	margin: 60px 0 0;
	line-height: 1.4;

	&__item {
		display: flex;
		
		&._prev_ {
			grid-area: prev;
			justify-content: flex-start;
		}
		&._next_ {
			grid-area: next;
			justify-content: flex-end;
		}
		&._list_ {
			grid-area: list;
			justify-content: center;
		}
	}
	&__link {
		display: block;
		padding: 10px 15px;
		background: #F3F4F9;
		border: 1px solid $line-gray;
		text-decoration: none;

		&._current_ {
			background: $baseColor;
			border: 1px solid $baseColor;
			color: $white;
			opacity: 1;
		}
	}
}
@include media-m-ov {
	&-pager {
		&__link {
			@include transition;
			&:hover {
				border: 1px solid $baseColor;
				background: $baseColor;
				color: $white;
				opacity: 1;
			}
		}
	}	
}
@include media-s {
	&-pager {
		&__item {
			margin: 0 3px 10px;
		}
		&__link {
			padding: 8px 12px;
		}
	}
}
