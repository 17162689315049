@charset "utf-8";
&-m {
	padding-right: $m-m !important;
}
@include media-s {
	&-m {
		&._sp-0_ {
			padding-right: 0 !important;
		}
	}
}
