@charset "utf-8";

@import url(//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap);
@import url(//fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;700&display=swap);

@import "./foundations/mixins/_";
@import "./foundations/vars/_";
@import "./foundations/resets/_";
@import "./foundations/bases/_";
@import "./foundations/icon/_";

@import "./elements/_";
@import "./modules/_";
@import "./components/_";
@import "./layouts/_";
@import "./templates/_";
@import "./application/_";
@import "./utility/_";