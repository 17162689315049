@charset "utf-8";
&-bg {
	&._bluelight_ {
		background: $bg-gray;
	}
}
@include media-m-ov {
	&-bg {
		padding-top: 70px;
	}
}

@include media-m {
	&-bg {
	}
}
@include media-s {
	&-bg {
		padding-top: 40px;
	}
}


