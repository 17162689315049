@charset "utf-8";
&-info {
	width: 100%;
	display: flex;
	justify-content: space-between;
	&__ttl {
		background: $baseColor;
		flex: 0 0 300px;
		padding: 20px 0;
		display: flex;
		align-items: center;
		@include font-serif;
		font-size: $fz-l;
		justify-content: center;
		color: $white;
		.__ico {
			color: $red;
			margin: 0 5px 0 0;
		}
	}
	&__list {
		background: #F5F5F5;
		flex: 1 1 auto;
		padding: 20px 0 20px 50px;
	}
	&__item {
		display: flex;
		align-items: center;
		margin: 0 0 10px;
		&:last-child {
			margin: 0;
		}
	}
	&__time {
		margin: 0 15px 0 0;
	}
}

@include media-m-ov {
	&-info {
		
	}
}
@include media-s {
	&-info {
		&__ttl {
			flex: 0 0 27%;
			font-size: $fz-m;
			text-align: center;
			line-height: 1.5;
			.__ico {
				display: none;
			}
		}
		&__list {
			padding: 15px;
		}
		&__item {
			display: block;
		}
		&__time {
			margin: 0;
			display: block;
		}
	}
}
