@charset "utf-8";
&-topic-list {
	border-top: 1px solid #E7E7E7;
	&__item {
		display: flex;
		flex-wrap: wrap;
		padding: 20px 0;
		border-bottom: 1px solid #E7E7E7;
	}
	&__date {
		flex: 0 0 auto;
		margin: 0 20px 0 0;
		line-height: 28px;
	}
	&__label {
		flex: 0 0 auto;
		&._sub_ {
			margin: 0 0 0 10px;
		}
	}
	&__link {
		padding: 0 0 0 15px;
		position: relative;
		text-decoration: none;
		color: $text-black;
		display: table;
		&::before {
			font-family: 'icomoon';
			content: "\f105";
			position: absolute;
			left: 0;
			color: $baseColorLight;
		}
		&._pdf_ {
			&::after {
				font-family: 'icomoon';
				content: "\f1c1";
				margin: 0 0 0 5px;
				color: $red;
				display: inline-block;
			}
		}
		&[target="_blank"] {
			&::after {
				font-family: 'icomoon';
				content: "\e908";
				margin: 0 0 0 5px;
				color: $baseColor;
				display: inline-block;
			}
		}
	}
	&__txt {
		width: 100%;
		margin: 10px 0 0 0;
	}
}
@include media-m-ov {
	&-topic-list {
		&__link {
			@include transition;
			&:hover {
				color: $baseColor;
				text-decoration: underline;
			}
		}
	}
}

@include media-s {
	&-topic-list {
		
		&__item {
			padding: 15px 0;
		}
		&__date {
			margin: 0 10px 10px 0;
		}
		&__label {
			margin: 0 0 10px 0;
			&._sub_ {
				margin: 0 0 0 5px;
			}
		}
		&__link {
			&::before {
				color: $baseColor;
			}
		}
	}
}
