@charset "utf-8";
&-h2 {
	margin: 0 0 40px;
	color: $baseColor;
	font-size: 3.6rem;
	line-height: 1.4;
	@include font-serif-bold;
	._ta-c_ {
		text-align: center;
	}
}

@include media-s {
	&-h2 {
		margin: 0 0 30px;
		font-size: 3.0rem;
	}
}
