@charset "utf-8";
&-fnav {
	border-top: 1px solid $line-gray;
	padding-top: 60px;
	&__list {
		max-width: 1200px;
		width: 100%;
		margin: 0 auto 50px;
		display: flex;
		flex-wrap: wrap;
		border: 1px solid $line-gray;
	}
	&__link {
		background: $white;
		text-decoration: none;
		display: flex;
		align-items: center;
		@include transition;
		padding: 15px;
		font-size: 1.5rem;
		color: $text-black;
		line-height: 1.4;
		height: 100%;
		.__ico {
			margin: 0 5px 0 0;
		}
		&._active_ {
			background: $bg-blueLight02;
			color: $baseColor;
		}
	}
	
}

@include media-m-ov {
	&-fnav {
		&__link {
			border-right: 1px solid $line-gray;
			border-bottom: 1px solid $line-gray;
			&:hover {
				background: $bg-blueLight02;
				color: $baseColor;
			}
		}
		&__list {
			border-bottom: none; 
			border-right: none;
			&._col2_ {
				border: none;
				display: flex;
				justify-content: center;
				.c-fnav__item {
					flex-basis: calc(100% / 3);
					@include iehack {
						flex-basis: calc((100% - 1px) / 3 );
					}
					&:first-child .c-fnav__link {
						border-left: 1px solid $line-gray;
					}
				}
				.c-fnav__link {
					border-top: 1px solid $line-gray;
				}
			}

			&._col3_ {
				.c-fnav__item {
					flex-basis: calc(100% / 3);
					
					@include iehack {
						flex-basis: calc((100% - 1px) / 3 );
					}
				}
			}

			&._col4_{
				.c-fnav__item {
					flex-basis: calc(100% / 4);
					@include iehack {
						flex-basis: calc((100% - 1px) / 4 );
					}
				}
			}

			&._col5_ {
				.c-fnav__item {
					flex-basis: calc(100% / 5);
					@include iehack {
						flex-basis: calc((100% - 1px) / 5 );
					}
					&:not(:nth-child(5n+1)) .c-fnav__link {
						border-left: none;
					}
				}
			}
		}
	}
}

@include media-s {
	&-fnav {
		&__link {
			padding: 10px 10px 10px 20px;
			line-height: 1.5;
		}
		&__list {
			&._col2_ {
				.c-fnav__item {
					flex-basis: calc(100% / 2);
					&:first-child .c-fnav__link {
						border-right: 1px solid $line-gray;
					}
				}
			}
			&._col4_{
				.c-fnav__item {
					flex-basis: calc(100% / 2);
					&:not(:nth-child(2n+1)) .c-fnav__link {
						border-left: 1px solid $line-gray;
					}
					&:not(:nth-child(-n+2)) .c-fnav__link {
						border-top: 1px solid $line-gray;
					}
				}
			}
			&._col3_,
			&._col5_{
				border-right: none;
				border-bottom: none;
				.c-fnav__item {
					flex-basis: calc(100% / 2);
				}
				.c-fnav__link {
					border-right: 1px solid $line-gray;
					border-bottom: 1px solid $line-gray;
				}
			}
		}
		
		//スマホ強制1カラム -----------------------------------
		&__list {
			&._sp-col1_ {
				.c-fnav__item {
					flex: 0 0 100%;
					width: 100%;

					@include iehack {
						flex: 0 0 100%;
					}
					&:not(:nth-child(2n+1)) {
						margin-left: 0;
					}
					&:not(:nth-child(-n+1)) {
						margin-top: 7px;
					}
				}
			}
		}
	}
}
