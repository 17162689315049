@charset "utf-8";
&-select {
	display: inline-block;
	border: 1px solid $line-gray;
	padding: 5px 10px;
	&._news_,
	&._zaiko_ {
		padding: 10px 15px;
		background: $bg-blueLight02;
		color: $baseColor;
		border: none;
		margin: 0 15px 0 0;
	}
	&._zaiko_ {
		min-width: 150px;
	}
}

@include media-m-ov {
	&-select {
		&._news_,
		&._zaiko_ {
			border-right: 10px solid $bg-blueLight02;
		}
	}
}

@include media-s {
	&-select {
		&._news_,
		&._zaiko_ {
			padding: 10px;
			margin: 0 0 10px 0;
			width: 100%;
		}
	}
}

