@charset "utf-8";
&-ttl01 {
	font-size: 3.0rem;
	color: $baseColor;
	@include font-serif-bold;
	padding: 0 0 0 40px;
	position: relative;
	margin: 0 0 35px;
	&::before {
		content: "";
		width: 20px;
		left: 0;
		height: 1px;
		border-bottom: 1px solid #F1F1F1;
		@include centering-elements(false, true);
	}
	&._line-blue_ {
		&::before {
			border-bottom: 1px solid #8895AA;
		}
	}
}

@include media-m {
	&-ttl01 {
		padding: 0 0 0 30px;
	}
}
@include media-s {
	&-ttl01 {
		margin: 0 0 25px;
	}
}
