@charset "utf-8";
&-table {
	width: 100%;
	
	th,
	td {
		padding: 15px 20px;
		border: 1px solid $line-gray;
		text-align: left;
		vertical-align: middle;
	}
	th {
		@include font-medium;
	}
	._al-l_ {
		text-align: left;
	}
	._al-r_ {
		text-align: right;
	}
	._al-c_ {
		text-align: center;
	}
	._nowrap_ {
		white-space: nowrap;
	}
	._navy_ {
		background: $baseColor;
		color: $white;
	}
	._gray_ {
		background: #f2f2f2;
	}
	
	&._scroll_ {
		overflow-x: auto;
		.m-table__item {
			width: 100%;
		}
	}
}


@include media-s {
	&-table {
		th,
		td {
			padding: 15px;
		}
		
		//SP 1カラム -------------------------------
		&._sp-col1_ {
			display: block;
			
			thead,
			tbody,
			tr,
			th,
			td {
				display: block;
				border-bottom: none;
			}
			th {
				padding: 10px 15px;				
			}
			tr:last-child {
				td {
					border-bottom: 1px solid $line-gray;
				}
			}
		}
		
		//横長 -------------------------------
		&._wide_ {
			border: none;
			display: block;

			th,
			td {
				display: block;
				padding: 5px 8px;
				border-bottom-width: 0;
				border-right-width: 0;
				text-align: center;
			}
			th{  
				width: auto;
				text-align: left;
			}
		}
		&._wide_ &__head {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		&._wide_ &__body {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		&._wide_ &__head th:last-child,
		&._wide_ &__body td:last-child {
			border-bottom-width: 1px;
		}
		
		//リスト風 -------------------------------
		&._list_ {
			border: none;

			tr {
				display:block;
				margin: 0 0 20px;
			}
			th {
				display: block;
			}
			td {
				display: list-item;
				margin: 0 0 0 20px;
				padding: 5px 0 0;
				border: none;
				background: none;
			}
		}
	}
}
