@charset "utf-8";
&-gnav {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	&Head {
		height: 65px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		&__link {
			.__elem {
				width: 160px;
			}
		}
	}
	&Menu {
		position: absolute;
		width: 65px;
		height: 65px;
		z-index: 10;
		margin-left: auto;
		cursor: pointer;
		transition: all 0.5s ease;
		background: $baseColorDarken;
		right: 0;
		display: none;

		&__item {
			display: inline-block;
			@include centering-elements(true, false);
			width: 60%;
			height: 2px;
			background: $white;
			@include radius(2);
			@include transition;

			&:nth-of-type(1) { top: 15px; }
			&:nth-of-type(2) { top: 27px; }
			&:nth-of-type(3) { top: 38px; }
		}
		&__txt {
			@include centering-elements(true, false);
			bottom: 15px;
			color: $white;
			font-size: $fz-xxs;
			@include font-serif;
			width: 100%;
			text-align: center;
			.__txt {
				&._close_ {
					display: none;
				}
			}
		}
		&._active_ {
			.c-gnavMenu__item {
				&:nth-of-type(1) { transform: translateY(4px) translateX(-19px) rotate(-335deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-19px) translateX(-20px) rotate(335deg); } 
			}
			.c-gnavMenu__txt {
				.__txt {
					&._open_ {
						display: none;
					}
					&._close_ {
						display: block;
					}
				}
			}
		}
		.index & {
			position: fixed;
			width: 80px;
			height: 80px;
			z-index: 10px;
			background: $baseColor;
			display: block;
		}
		.index &._active_ {
			z-index: 10;
			position: absolute;
			width: 65px;
			height: 65px;
			background: $baseColorDarken;
			display: none;
		}
	}
	&__wrap {	
		top: 0;
		z-index: 1;
		opacity: 1;
		pointer-events: auto;
		right: 0;
		transition: right 0.5s ease;
		background: $baseColor;
		width: 280px;
		height: 100%;
		position: absolute;
		.index & {
			right: -280px;
			pointer-events: none;
		}
		.index._gnav-show_ & {
			z-index: 1;
			opacity: 1;
			pointer-events: auto;
			right: 0;
		}
	}
	&__inner {
		min-height: 100%;
	}
	&Menu1 {
		display: flex;
		&__item {
			width: 50%;
			height: 45px;
			&:first-child {
				border-right: 1px solid $baseColor;
			}
		}
		&__link {
			background: #2D3C80;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			text-decoration: none;
			font-size: $fz-s;
			@include transition;
			.__ico {
				margin: 0 5px 0 0;
				font-size: $fz-l;
			}
		}
	}
	&Menu2 {
		padding: 20px 20px;
		&__link {
			display: flex;
			align-items: center;
			border: 1px solid #A0A8CF;
			color: $white;
			text-decoration: none;
			padding: 8px 30px;
			font-size: 1.5rem;
			&:first-child {
				margin: 0 0 15px;
			}
			.__ico {
				color: #8C99CF;
				margin: 0 15px 0 0;
			}
		}
	}
	&Menu3 {
		&__item {
			border-bottom: 1px solid #2E3C7B;
			font-size: 1.5rem;
			&:first-child {
				border-top: 1px solid #2E3C7B;
			}
			.guide &._guide_,
			.study &._study_,
			.future &._future_,
			.life &._life_,
			.examination &._admissions_ {
				background: $baseColorDarken;
			}
		}
		&__link {
			color: $white;
			text-decoration: none;
			position: relative;
			width: 100%;
			height: 100%;
			padding: 12px 15px;
			display: block;
			@include font-serif;
		}
		&__subitem {
			display: block;
			padding: 15px 15px 15px 30px;
			color: $white;
			text-decoration: none;
			font-size: 1.5rem;
			@include transition;
			.__ico {
				margin: 0 5px 0 0;
				color: #ABB2C4;
				display: inline-block;
				@include iehack {
					height: 1em;
					overflow-y: hidden;
				}
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
	&Menu4 {
		padding: 15px 15px 20px;
		&__link {
			color: $white;
			text-decoration: none;
			@include font-serif;
			font-size: 1.3rem;
			@include transition;
			.__ico {
				margin: 0 5px 0 0;
				color: #ABB2C4;
				display: inline-block;
				@include iehack {
					height: 1em;
					overflow-y: hidden;
				}
			}
			.icon-filter_none {
				color: $white;
				margin: 0 0 0 5px;
			}
		}
	}

	&Menu5 {
		display: flex;
		margin: 0 10px;
		justify-content: center;
		&__item {
			border: 1px solid #A0A8CF;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px 15px;
			color: $white;
			text-decoration: none;
			padding: 10px 10px;
			font-size: 1.3rem;
			@include transition;
			.__ico {
				font-size: 1.5rem;
				margin: 0 5px 0 0;
				color: #ABB2C4;
			}
		}
	}
}
@include media-m-ov {
	&-gnav {
		min-height: 100%;
		.index._gnav-show_ & {
			min-height: 100%;
			width: 280px;
			overflow: inherit;
			height: inherit;
		}
		&._nav-fixed_ {
			position: fixed;
		}
		
		&Menu1 {
			&__link {
				&:hover {
					background: $baseColorDarken;
					color: $white;
				}
			}
		}
		&Menu2 {
			&__link {
				@include transition;
				&:hover {
					background: #A0A8CF;
					color: $baseColor;
					.__ico {
						color: $baseColor;
					}
				}
			}
		}
		&Menu3 {
			&__item {
				@include transition;
				cursor: pointer;
				&:nth-child(2) {
					border-top: 1px solid #2E3C7B;
				}
				&:hover {
					background: $baseColorDarken;
					.c-gnavMenu3__link {
						background: $baseColorDarken;
					}
				}
				&._active_ {
					.c-gnavMenu3__dropdown {
						padding: 270px 0 0 0;
						transition: opacity 0.6s ease;
						width: 280px;
						height: 100%;
						overflow: hidden;
						position: absolute;
						top: 0;
						right: 280px;
						cursor: default;
						opacity: 1.0;
						filter: alpha(opacity=0);
						background-color: $baseColorDarken;
					}
					.c-gnavMenu3__subitem {
						pointer-events: inherit;
					}
				}
			}
			&__link {
				@include transition;
				.__ico {
					display: none;
				}
			}
			&__dropdown {
				opacity: 0;
				width: 0;
				height: 0;
			}
			&__subitem {
				@include transition;
				pointer-events: none;
				&:hover {
					background: #C2C8E3;
					color: $baseColor;
					.__ico {
						color: $baseColor;
					}
				}
			}
		}
		&Menu4 {
			&__link {
				&:hover {
					color: #8C99CF;
				}
			}
		}
		&Menu5 {
			&__item {
				&:hover {
					background: #A0A8CF;
					color: $baseColor;
					.__ico {
						color: $baseColor;
					}
				}
			}
		}
	}
}

@include media-m {
	&-gnav {
		position: fixed;
		height: 80px;
		width: 80px;
		overflow: hidden;
		min-height: inherit;
		._gnav-show_ & {
			min-height: 100%;
			width: 280px;
			overflow: inherit;
			height: inherit;
		}
		._gnav-show_ & {
			min-height: 100%;
		}
		&Menu {
			.index &._active_ {
				display: block;
			}
		}
		&__inner {
			overflow-y: auto;
			overflow-x: hidden;
			height: 100vh;
			padding-bottom: 50px;
			&::-webkit-scrollbar{
			  width: 10px;
			}
			&::-webkit-scrollbar-track{
			  background: #2D3C80;
			  border: none;
			}
			&::-webkit-scrollbar-thumb{
			  background: rgba(0,0,0,1.0);
			  border-radius: 10px;
			  box-shadow: none;
			}
		}
		&Menu {
			display: block;
			position: fixed;
			width: 80px;
			height: 80px;
			z-index: 0;
			background: $baseColor;
			&._active_ {
				z-index: 10;
				position: absolute;
				width: 65px;
				height: 65px;
				background: $baseColorDarken;
			}
		}
		&__wrap {
			right: -280px;
			z-index: -1;
			opacity: 0;
			pointer-events: none;
			._gnav-show_ & {
				z-index: 1;
				opacity: 1;
				pointer-events: auto;
				right: 0;
			}
		}
	}
}

@include media-s {
	&-gnav {
		top: 0;
		right: 0;
		width: 0;
		position: fixed;
		._gnav-show_ & {
			width: 100%;
			height: 100%;
			background: $baseColor;
		}
		&Menu {
			position: fixed;
			width: 60px;
			height: 60px;
			z-index: 10;
			top: 0;
			display: block;
			.index & {
				width: 60px;
				height: 60px;
			}
			&__item {
				&:nth-of-type(1) { top: 12px; }
				&:nth-of-type(2) { top: 22px; }
				&:nth-of-type(3) { top: 32px; }
			}
			&__txt {
				bottom: 7px;
			}
			&._active_ {
				width: 60px;
				height: 60px;
				border-left: 1px solid #2E3C7B;
				right: 0;
				position: fixed;
				.c-gnavMenu__item {
					&:nth-of-type(1) { transform: translateY(10px) translateX(-17px) rotate(-335deg); }
					&:nth-of-type(2) { opacity: 0; }
					&:nth-of-type(3) { transform: translateY(-10px) translateX(-18px) rotate(335deg); } 
				}
			}
			.index &._active_ {
				width: 60px;
				height: 60px;
				position: fixed;
				display: block;
			}
		}
		&Head {
			display: none;
		}
		&__wrap {
			top: 61px;
			width: 100%;
			transition: top 0.5s ease;
		}
		&__inner {
			overflow-y: auto;
			background: $baseColor;
			height: 100vh;
			&::-webkit-scrollbar{
			  width: 10px;
			}
			&::-webkit-scrollbar-track{
			  background: #2D3C80;
			  border: none;
			}
			&::-webkit-scrollbar-thumb{
			  background: rgba(0,0,0,1.0);
			  border-radius: 10px;
			  box-shadow: none;
			}
		}
		&Menu1 {
			border-top: 1px solid #3D4C87;
		}
		&Menu3 {
			&__link {
				padding: 15px;
				.__ico {
					@include centering-elements(false, true);
					right: 15px;
				}
				&._drop_ {
					.icon-angle-down::before {
						content: "\f106";
					}
				}
			}
			&__dropdown {
				display: none;
				background: $baseColorDarken;
				padding: 15px 0 5px;
			}
			&__subitem {
				pointer-events: inherit;
			}
		}
		&Menu4 {
			padding: 30px 15px 15px;
			&__item {
				padding: 0 0 20px;
			}
		}
		&Menu5 {
			padding: 0 40px 40px;
			margin-bottom: 100px;
		}
	}
}
