@charset "utf-8";
&-h1 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 215px;
	background-image: url(/common/img/common/bg_ttl01.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	font-size: 4.5rem;
	text-align: center;
	line-height: 1.2;
	@include font-a1;
	color: $white;
	&._news_ {
		background-image: url(/common/img/news/bg_ttl01.png);
	}
	&._access_ {
		background-image: url(/common/img/access/bg_ttl01.png);
	}
	&._contact_ {
		background-image: url(/common/img/contact/bg_ttl01.png);
	}
	&._examination_ {
		background-image: url(/common/img/examination/bg_ttl01.png);
	}
	&._future_ {
		background-image: url(/common/img/future/bg_ttl01.png);
	}
	&._guide_ {
		background-image: url(/common/img/guide/bg_ttl01.png);
	}
	&._life_ {
		background-image: url(/common/img/life/bg_ttl01.png);
	}
	&._recruit_ {
		background-image: url(/common/img/recruit/bg_ttl01.png);
	}
	&._study_ {
		background-image: url(/common/img/study/bg_ttl01.png);
	}
}

@include media-s {
	&-h1 {
		height: 170px;
		font-size: 2.8rem;
	}
}
