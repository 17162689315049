@charset "utf-8";
&-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	&Logo {
		background: url(/common/img/common/bg_logo.png) no-repeat;
		background-size: cover;
		text-align: center;
		width: 320px;
		&__link {
			display: block;
			padding: 20px 30px;
			.__elem {
				width: 100%;
			}
		}
	}
}
@include media-m-ov {
	&-header {

	}
}
@include media-m {
	&-header {

	}
}
@include media-s {
	&-header {
		width: 100%;
		&Logo {
			background: url(/common/img/common/bg_logo.png) no-repeat;
			background-size: 60%;
			text-align: left;
			width: calc( 100% - 60px);
			&__link {
				display: flex;
				align-items: center;
				padding: 0 15px;
				height: 60px;
				.__elem {
					width: 180px;
				}
			}
		}
		&Logo {
		}
		._gnav-show_ & {
			background: $baseColor;
			border-bottom: 1px solid #2E3C7B;
			.c-headerLogo {
				background: none;
			}
		}
	}
}
