@charset "utf-8";
body {
	@include font-normal;
}
a {
	color: $text-black;
	text-decoration: underline;
	
	.icon-filter_none::before,
	.icon-file-pdf-o::before,
	.icon-file-excel-o::before,
	.icon-file-word-o::before {
		display: inline-block;
		
		@include iehack {
			height: 1em;
			overflow-y: hidden;
		}
	}
}
sup {
	vertical-align: super;
}
input,
textarea {
	appearance: none;
	max-width: 100%;
}
input::placeholder {
	opacity: .5;
}
button,
input[type="button"],
input[type="submit"] {
	appearance: none;
}
.icon-filter_none {
	margin: 0 3px;
	color: $baseColor;
}
.icon-file-pdf-o,
.icon-file-excel-o,
.icon-file-word-o {
	margin: 0 5px;
	color: $baseColor;	
}
[class^="icon-"] .__hidden,
[class*=" icon-"] .__hidden {
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    position: absolute;
    top: -999999px;
    left: -999999px;
    width: 1px;
    height: 1px;
    font-size: 1px;
    text-indent: -999999px;
}

@include media-m-ov {
	html {
		overflow-y: scroll;
	}
	a {
		&:hover {
			text-decoration: none;
			color: $baseColor;
		}
		&._opacity_ {
			&:hover {
				img {
					@include transition;
					opacity: 0.7;
				}
			}
		}
		&[href^="tel:"] {
			color: $text-black;
			text-decoration: none;
			pointer-events: none;
		}
	}
}

@include media-m {
	html {
		font-size: calc(100vw / 102.4);
		._gnav-show_ & {
			overflow: hidden;
		}
	}
}

@include media-s {
	html.iziModal-isOverflow {
		overflow: inherit;
	}
	body {
		&._gnav-show_ {
			overflow-y: hidden;
		}
	}
	input,
	select,
	textarea {
		font-size: $fz-m !important;
	}	
}