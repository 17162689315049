@charset "utf-8";
&-news-list {
	&__item {
		text-decoration: none;
		@include transition;
	}
	&__img {
		position: relative;
		padding-top: 68.97%;
		overflow: hidden;
		background: $white;
		.__elem {
			@include centering-elements(true, true);
			@include transition;
		}
	}
	&__body {
		width: 100%;
		padding: 30px 25px;
		background: $white;
		color: $text-black;
		line-height: 1.6;
		@include transition;
		position: relative;
		.__label {
			position: absolute;
			left: 25px;
			top: -11px;
			z-index: 2;
		}
		.__date {
			margin: 0 0 5px;
			display: block;
		}
		.__club {
			position: relative;
			font-size: $fz-xs;
			color: #7A7A7A;
			padding: 0 0 0 10px;
			margin: 0 0 5px;
			&::before {
				border-bottom: 1px solid #7A7A7A;
				width: 6px;
				@include centering-elements(false, true);
				content: "";
				height: 1px;
				left: 0;
			}
		}
		.__text {
			position: relative;
			padding: 0 0 0 15px;
			&::before {
				position: absolute;
				top: 0.05em;
				content: "\F105";
				left: 0;
				color: #344CB9;
				font-family: 'icomoon';
			}
		}
	}
}
@include media-m-ov {
	&-news-list {
		&__item {
			&:hover {
				text-decoration: none;
				box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.3);
				.c-news-list__img {
					.__elem {
						transform: translate(-50%, -50%) scale(1.2);
					}
				}
				.__text {
					@include transition;
					color: $baseColor;
				}
			}
		}
	}
}
@include media-s {
	&-news-list {
		&__item {
			flex-wrap: wrap;
			padding: 0 0 15px;
		}
		&__date {
			margin: 0 10px 0 0;
			font-size: $fz-xxs;
		}
		&__label {
			line-height: 1.2;
		}
		&__link {
			width: 100%;
			margin: 10px 0 0;
		}
		&__body {
			padding: 25px 5px 0;
			.__label {
				left: 10px;
			}
			.__text {
				&::before {
					color: $baseColor;
				}
			}
		}
	}
}
