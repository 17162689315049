@charset "utf-8";
@include media-m-ov {
	&-row {
		padding-bottom: 70px;
		&__inner {
			max-width: 1240px;
			padding: 0 20px;
			margin: 0 auto;
			&._narrow01_ {
				max-width: 1160px;
			}
			&._narrow02_ {
				max-width: 960px;
			}
		}
	}
}

@include media-m {
	&-row {
		&__inner {
			padding: 0 25px;
		}
	}
}
@include media-s {
	&-row {
		padding-bottom: 40px;
		&__inner {
			max-width: 100%;
			padding: 0 15px;
			margin: 0 auto;
		}
	}
}


