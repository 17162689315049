@charset "utf-8";
&-pagination {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 60px 0 0;
	line-height: 1.4;

	&__item {
		margin: 0 3px 5px;
	}
	&__link {
		display: block;
		padding: 10px 15px;
		background: #F3F4F9;
		border: 1px solid $line-gray;
		text-decoration: none;

		&._current_ {
			background: $baseColor;
			border: 1px solid $baseColor;
			color: $white;
			opacity: 1;
		}
	}
}
@include media-m-ov {
	&-pagination {
		&__link {
			@include transition;
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
			}
		}
	}	
}
@include media-s {
	&-pagination {
		&__item {
			margin: 0 3px 10px;
		}
		&__link {
			padding: 8px 12px;
		}
	}
}
