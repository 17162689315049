@charset "utf-8";
&-lnav {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto 50px;
	display: flex;
	flex-wrap: wrap;
	&._jc-c_ {
		justify-content: center;
	}
	&__link {
		background: $bg-blueLight;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		@include transition;
		padding: 10px 15px 10px 10px;
		font-size: $fz-s;
		height: 100%;
		.__ico {
			@include centering-elements(false, true);
			right: 10px;
		}
	}
}

@include media-m-ov {
	&-lnav {
		&__link {
			&:hover {
				background: $baseColor;
				color: $white;
			}
		}
		&._col2_ {
			.c-lnav__item {
				flex-basis: calc((100% - 7px) / 2);
				@include iehack {
					flex-basis: calc((100% - 8px) / 2);
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 7px;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: 7px;
				}
			}
		}
		&._col3_ {
			.c-lnav__item {
				flex-basis: calc((100% - 14px) / 3);
				@include iehack {
					flex-basis: calc((100% - 15px) / 3);
				}
				&:not(:nth-child(3n+1)) {
					margin-left: 7px;
				}
				&:not(:nth-child(-n+3)) {
					margin-top: 7px;
				}
			}
		}
		&._col4_ {
			.c-lnav__item {
				flex-basis: calc((100% - 21px) / 4);
				@include iehack {
					flex-basis: calc((100% - 22px) / 4);
				}
				&:not(:nth-child(4n+1)) {
					margin-left: 7px;
				}
				&:not(:nth-child(-n+4)) {
					margin-top: 7px;
				}
			}
		}
	}
}

@include media-s {
	&-lnav {
		.c-lnav__link {
			padding: 10px 20px 10px 10px;
		}
		&._col2_,
		&._col3_,
		&._col4_ {
			.c-lnav__item {
				flex-basis: calc((100% - 7px) / 2);
				@include iehack {
					flex-basis: calc((100% - 8px) / 2);
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 7px;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: 7px;
				}
			}
		}
		//スマホ強制1カラム -----------------------------------
		&._sp-col1_ {
			.c-lnav__item {
				flex: 0 0 100%;
				width: 100%;

				@include iehack {
					flex: 0 0 100%;
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 7px;
				}
			}
		}
	}
}
