@charset "utf-8";
@include media-m-ov {
	&-news {
		background: url(/common/img/index/bg_index_01_pc.png) top center no-repeat;
		background-size: contain;
		&Head {
			display: flex;
			align-items: center;
			&__category {
				flex: 1 1 auto;
				display: flex;
				justify-content: flex-end;
			}
			&__filter {
				display: flex;
				justify-content: center;
				margin: 0 0 45px;
			}
			&__item {
				position: relative;
				padding: 0 15px 10px;
				color: $text-black;
				border-bottom: 1px solid #9FAFCB;
				text-decoration: none;

				&::before {
					position: absolute;
					left: 50%;
					bottom: -1px;
					z-index: 5;
					content: "";
					width: 0;
					height: 1px;
					background: $baseColor;
					backface-visibility: hidden;
					transform: translateX(-50%);
					@include transition;
				}
				&._active_,
				&:hover {
					text-decoration: none;
					&::before {
						width: 100%;
					}
				}
			}
		}
		&Body {
			margin: 0 -10px;
		}
	}
}

@include media-m {
	&-news {
		
	}
}
@include media-s {
	&-news {
		background: url(/common/img/index/bg_index_01_sp.png) top center no-repeat;
		background-size: contain;
		&Head {
			&__category {
				display: block;
				position: relative;
				z-index: 3;
				margin: 0 0 25px;
			}

			&__select {
				position: relative;
				display: flex;
				align-items: center;
				padding: 0 15px;
				height: 48px;
				background: $white;
				border: 1px solid #959FAF;
				color: $text-black;
				cursor: default;
				text-decoration: none;

				.__text {
					flex: 1 1 auto;
				}
				.__ico {
					flex: 0 0 auto;
				}
				&._select-focus_ {
					.__ico {
						transform: rotate(180deg);
					}
				}
			}
			
			&__pulldown {
				display: none;
				position: absolute;
				left: 0;
				top: 48px;
				width: 100%;
				background: $baseColor02;
			}
			&__item {
				display: block;
				padding: 10px 15px;
				color: $white;
				text-decoration: none;

				&:not(:last-child) {
					border-bottom: 1px solid #959FAF;
				}
				&._selected_ {
					background: $baseColor;
				}
			}
		}
		&Body {
			margin: 0 -5px;
		}
	}
}
